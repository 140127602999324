import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IReleasedRuleList } from "%%/labels/interfaces"

export class ReleasedRuleListEn extends AbstractLocaleMessageObject implements IReleasedRuleList {
  readonly authoriModel: string = "Detection Model"
  readonly listDescription: string =
    "※The rules displayed are the latest. They may differ from the rules used in past detections."
  readonly csvOutput: string = "CSV Output"
  readonly ruleGroupName: string = "Group Name"
  readonly priority: string = "Priority"
  readonly judgement: string = "Judgement"
  readonly ruleUnitNo: string = "Unit Number"
  readonly ruleUnitLogicalCondition: string = "Unit Operator"
  readonly ruleUnitThreshold: string = "Threshold"
  readonly ruleDescription: string = "Rule Description"
  readonly ruleCode: string = "Rule Code"
  readonly noData: string = "no data available"
}
