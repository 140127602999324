import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventDetailJa extends AbstractLocaleMessageObject {
  readonly eventId: string = "イベントID"

  readonly eventAuthori: string = "審査情報"

  readonly eventAuthoriShopEventId: string = "加盟店管理ID"

  readonly eventAuthoriAaresult: string = "自動審査結果"

  readonly eventAuthoriCreatedAt: string = "自動審査日時"

  readonly eventAuthoriAuthStatus: string = "審査ステータス"

  readonly eventAuthoriMaresult: string = "目視審査結果"

  readonly eventAuthoriDeadline: string = "目視審査締切日時"

  readonly eventAuthoriAssigneeName: string = "審査担当者"

  readonly eventAuthoriLimitPrice: string = "上限金額"

  readonly eventAuthoriRuleGroupName: string = "判定結果グループ名"

  readonly eventAuthoriTotalAmount: string = "決済金額合計"

  readonly eventDetail: string = "対象・関連イベント"

  readonly eventDetailRelatedEventRelatedEventId: string = "関連イベントID"

  readonly eventDetailRelatedEventMaAdditionAmountFlg: string = "ma_addition_amount_flg"

  readonly eventDetailBasic: string = "基本情報"

  readonly eventDetailBasicShopEventId: string = "加盟店管理ID"

  readonly eventDetailBasicAuthoriModelId: string = "審査モデルID"

  readonly eventDetailBasicRulePackageId: string = "ルールパッケージID"

  readonly eventDetailBasicAaresult: string = "自動審査結果"

  readonly eventDetailBasicMaresult: string = "目視審査結果"

  readonly eventDetailBasicSettleStatus: string = "決済ステータス"

  readonly eventDetailBasicCreatedAt: string = "自動審査日時"

  readonly eventDetailBasicAmount: string = "決済金額"

  readonly eventDetailBasicMethod: string = "決済方法"

  readonly eventDetailBasicDaysToPayment: string = "入金までの日数"

  readonly eventDetailBasicTenantName: string = "店子"

  readonly eventDetailBasicNegatived: string = "ネガティブ登録"

  readonly eventDetailBasicLabelNames: string = "ラベル名"

  readonly eventDetailBasicRequestedAt: string = "審査申請日時"

  readonly eventDetailPurchaser: string = "購入者情報"

  readonly eventDetailPurchaserShopMemberId: string = "購入者会員ID"

  readonly eventDetailPurchaserLastName: string = "苗字"

  readonly eventDetailPurchaserFirstName: string = "名前"

  readonly eventDetailPurchaserZipcode: string = "郵便番号"

  readonly eventDetailPurchaserFullAddress: string = "住所"

  readonly eventDetailPurchaserTel: string = "固定電話番号"

  readonly eventDetailPurchaserTelStatus: string = "固定疎通"

  readonly eventDetailPurchaserMobileTel: string = "携帯電話番号"

  readonly eventDetailPurchaserMobileTelStatus: string = "携帯疎通"

  readonly eventDetailPurchaserMailAccount: string = "PCメールアカウント"

  readonly eventDetailPurchaserMailDomain: string = "PCメールドメイン"

  readonly eventDetailPurchaserMobileMailAccount: string = "携帯メールアカウント"

  readonly eventDetailPurchaserMobileMailDomain: string = "携帯メールドメイン"

  readonly eventDetailPurchaserBirthdate: string = "生年月日"

  readonly eventDetailPurchaserSex: string = "性別"

  readonly eventDetailPurchaserCompany: string = "会社名"

  readonly eventDetailPurchaserDepart: string = "部署名"

  readonly eventDetailPurchaserPost: string = "役職"

  readonly eventDetailPurchaserExistingCustomerFlg: string = "顧客分類"

  readonly eventDetailPurchaserMediaCode: string = "メディアコード"

  readonly eventDetailShippings: string = "配送先情報"

  readonly eventDetailShippingShopMemberId: string = "配送先会員ID"

  readonly eventDetailShippingLastName: string = "苗字"

  readonly eventDetailShippingFirstName: string = "名前"

  readonly eventDetailShippingZipcode: string = "郵便番号"

  readonly eventDetailShippingFullAddress: string = "住所"

  readonly eventDetailShippingTel: string = "固定電話番号"

  readonly eventDetailShippingTelStatus: string = "固定疎通"

  readonly eventDetailShippingMobileTel: string = "携帯電話番号"

  readonly eventDetailShippingMobileTelStatus: string = "携帯疎通"

  readonly eventDetailShippingMailAccount: string = "PCメールアカウント"

  readonly eventDetailShippingMailDomain: string = "PCメールドメイン"

  readonly eventDetailShippingMobileMailAccount: string = "携帯メールアカウント"

  readonly eventDetailShippingMobileMailDomain: string = "携帯メールドメイン"

  readonly eventDetailShippingDeliveryCompany: string = "配送業者"

  readonly eventDetailItems: string = "注文情報"

  readonly eventDetailItemsItem: string = "商品"

  readonly eventDetailItemsPrice: string = "単価"

  readonly eventDetailItemsQuantity: string = "個数"

  readonly eventDetailDevice: string = "端末等情報"

  readonly eventDetailDeviceCookie: string = "Cookie"

  readonly eventDetailDevicePrimaryDid: string = "デバイスID"

  readonly eventDetailDeviceIpaddress: string = "IPアドレス"

  readonly eventDetailDeviceDomainName: string = "IPドメイン"

  readonly eventDetailDeviceLineJName: string = "回線種別"

  readonly eventDetailDeviceCountryJName: string = "IP国情報"

  readonly eventDetailDevicePrefCityJName: string = "IP地域情報"

  readonly eventDetailDeviceCityLatitudeLongitude: string = "緯度/経度"

  readonly eventDetailDeviceTimeZone: string = "タイムゾーン"

  readonly eventDetailDeviceDeviceType: string = "デバイス種別"

  readonly eventDetailDeviceUseragent: string = "ユーザーエージェント文字列"

  readonly eventDetailDeviceBrowserLanguage: string = "言語設定"

  readonly eventDetailDeviceAuthoriId: string = "O-MOTION審査ID"

  readonly eventDetailDeviceResult: string = "O-MOTION審査結果"

  readonly eventDetailDeviceUserDeviceId: string = "端末ID"

  readonly eventDetailDeviceDidShort: string = "DID short"

  readonly eventDetailDeviceDidMiddle: string = "DID middle"

  readonly eventDetailDeviceCountryAName: string = "国名（英語表記）"

  readonly eventDetailDevicePrefAName: string = "都道府県名（英語表記）"

  readonly eventDetailCard: string = "カード情報"

  readonly eventDetailCardIssuer: string = "カード発行元"

  readonly eventDetailCardCardBrand: string = "カードブランド"

  readonly eventDetailCardBincode: string = "BIN"

  readonly eventDetailCardCchash: string = "カード番号ハッシュ"

  readonly eventDetailCardExpirationDate: string = "有効期限"

  readonly eventDetailCardAuthoriCode: string = "オーソリ結果コード"

  readonly eventDetailCardAuthoriComment: string = "オーソリ結果コメント"

  readonly eventDetailCardPaymentCount: string = "支払回数"

  readonly eventDetailOther: string = "その他"

  readonly eventDetailOtherMemo: string = "メモ"

  readonly firedRules: string = "発動ルール情報"

  readonly firedRulesRuleCode: string = "ルールコード"

  readonly firedRulesRuleDescription: string = "ルール説明"

  readonly firedRulesRuleGroupName: string = "グループ名"

  readonly manualAuthoriHistories: string = "目視審査結果履歴"

  readonly manualAuthoriHistoriesUpdatedAt: string = "更新日時"

  readonly manualAuthoriHistoriesResult: string = "審査結果"

  readonly manualAuthoriHistoriesReason: string = "目視審査結果理由"

  readonly manualAuthoriHistoriesRegistUserName: string = "目視実施者名"

  readonly manualAuthoriHistoriesMemo: string = "目視審査メモ"

  readonly btnFireRulesAllShowOpen: string = "▼すべて表示"

  readonly btnFireRulesAllShowClose: string = "▲閉じる"

  readonly btnOk: string = "OK"

  readonly btnNg: string = "NG"

  readonly btnHold: string = "HOLD"

  readonly btnUpdateNegative: string = "ネガティブ更新"

  readonly btnAddAuthoriMemo: string = "審査メモ登録"

  readonly btnAddRegistUser: string = "審査担当者登録"

  readonly btnUpdateLabel: string = "ラベル更新"

  readonly btnCustomizedItems: string = "表示項目変更"

  readonly btnPrev: string = "＜前へ"

  readonly btnNext: string = "次へ＞"

  readonly btnBackTo: string = "戻る"

  readonly noRelativeData = "関連イベントは0件です"
}
