import { MenuItem } from "@/models/topMenu/MenuItem"

/**
 * メニューデータを定義します。
 */
export const opluxmenu: Array<MenuItem> = [
  {
    menuId: "event",
    description: "イベント",
    path: "/EventList",
    nameId: "labels.menuLabels.event",
    icon: "",
    screenId: "EventList",
    disabled: true,
  },
  {
    menuId: "csvAuthori",
    description: "CSV一括",
    path: "/CsvAuthoriHistory",
    nameId: "labels.menuLabels.csvAuthori",
    icon: "",
    screenId: "CsvAuthoriHistory",
  },
  {
    menuId: "negativeList",
    description: "ネガティブ一覧",
    path: "/NegativeList",
    nameId: "labels.menuLabels.negativeList",
    icon: "",
    screenId: "NegativeList",
  },
  {
    menuId: "whiteList",
    description: "ホワイト一覧",
    path: "/white",
    nameId: "labels.menuLabels.whiteList",
    icon: "",
    screenId: "WhiteList",
  },
  {
    menuId: "report",
    description: "レポート",
    path: "/EventReportNegative",
    nameId: "labels.menuLabels.report",
    icon: "",
    screenId: "EventReportNegative",
  },
  {
    menuId: "authoriCondition",
    description: "審査条件",
    path: "/releasedRuleList",
    nameId: "labels.menuLabels.authoriCondition",
    icon: "",
    screenId: "ReleasedRuleList",
  },
  {
    menuId: "operator",
    description: "管理",
    nameId: "labels.menuLabels.operator",
    icon: "",
    screenId: "",
    children: [
      {
        menuId: "operatorList",
        description: "オペレータ一覧",
        path: "/OperatorList",
        nameId: "labels.menuLabels.operatorList",
        icon: "",
        screenId: "OperatorList",
      },
      {
        menuId: "operatorRoleList",
        description: "ロール一覧",
        path: "/OperatorRoleList",
        nameId: "labels.menuLabels.operatorRoleList",
        icon: "",
        screenId: "OperatorRoleList",
      },
      {
        menuId: "allowedIpAddress",
        description: "加盟店IPアドレス詳細/編集",
        path: "/AllowedIpAddress",
        nameId: "labels.menuLabels.allowedIpAddress",
        icon: "",
        screenId: "AllowedIpAddress",
      },
    ],
  },
]
