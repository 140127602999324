import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

import { IMenuLabels } from "%%/labels/interfaces"

export class MenuLabelsEn extends AbstractLocaleMessageObject implements IMenuLabels {
  readonly event = "Event"

  readonly report = "Report"

  readonly csvAuthori = "CSV Detection/Update"

  readonly negativeList = "Negative Registration"

  readonly whiteList = "White Registration"

  readonly operator = "Management"

  readonly operatorList = "Operator List"

  readonly operatorRoleList = "Role List"

  readonly allowedIpAddress = "Allowed IP Address"

  readonly authoriCondition = "Detection Condition"
}
