import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class ReleasedRuleListJa extends AbstractLocaleMessageObject {
  readonly authoriModel: string = "審査モデル"
  readonly listDescription: string =
    "※表示されているルールは最新状態のものです。過去の審査実施時点の内容とは異なる場合があります。"
  readonly csvOutput: string = "CSV出力"
  readonly ruleGroupName: string = "グループ名"
  readonly priority: string = "優先順位"
  readonly judgement: string = "判定"
  readonly ruleUnitNo: string = "ユニット番号"
  readonly ruleUnitLogicalCondition: string = "ユニット演算子"
  readonly ruleUnitThreshold: string = "閾値"
  readonly ruleDescription: string = "ルール説明"
  readonly ruleCode: string = "ルールコード"
  readonly noData: string = "データがありません"
}
